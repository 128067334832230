import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth_token: null, //user login token
    kill_chat_aliases: null, //when this changes, all alive chat_aliases and chat_alias
    kill_audio_aliases: null, //when this changes, all alive audio_aliases and audio_alias
    kill_agents: null, //when this changes, all alive agents and agent
  },
  mutations: {
    authToken (state, value) {
      state.auth_token = value
    },
    killChatAliases (state, value) {
      state.kill_chat_aliases = value
    },
    killAudioAliases (state, value) {
      state.kill_audio_aliases = value
    },
    killAgents (state, value) {
      state.kill_agents = value
    }
  },
  getters: {

    authToken: state => {
      return state.auth_token
    },
    killChatAliases: state => {
      return state.kill_chat_aliases
    },
    killAudioAliases: state => {
      return state.kill_audio_aliases
    },
    killAgents: state => {
      return state.kill_agents
    },

  },
  actions: {
  },
  modules: {
  }

})
