//Set of default variables used throughout the app
export default {
    data: function () {
        return {
            models: [], //the dataset
            options: {}, //the data table options (synced from data table)
            loading: false, //is api call loading
            keyword: "", //search keyword
            search_timeout: null, //timeout of the automatic search
            item: {},
            chat_alias_profile_picture_base: 'https://d3ihx5eec010ds.cloudfront.net/',
            chat_alias_premium_picture_base: 'https://d322lwxk3oodl4.cloudfront.net/',
            audio_alias_advertise_picture_base: 'https://d21xguvdgn2bha.cloudfront.net/',
            error: '', //string of error (chat_alias)
            snackbar: false,
            snackbar_timeout: 5000,
            snackbar_message: null,
        }
    },
    watch: {

        options: {
            handler () {

                this.mount()
            },
            deep: true,
        },

        keyword(){
            clearTimeout(this.search_timeout)
            this.search_timeout = setTimeout(() => {
                this.mount()
            }, 500)
        }
    },
    computed: {

        snackbarColor(){
            if(this.snackbar_type == 'success') return 'green'
            if(this.snackbar_type == 'error') return 'red'
        }
    },
    methods: {

        refreshModels(){
            this.models = []
            this.mount()
        },

        resetForm(ref){
            if(this.$refs[ref]) this.$refs[ref].reset()
        },

        deleteModel(route){

            this.axios.delete(route);

        },

        submitModel(route, model){

            this.axios.post(route, model);

        },

        //what happens when chips is closed
        chipsClose(chip, item){

            this.item[chip.item_id].splice(this.item[chip.item_id].findIndex(x => x.id == item.id), 1)

            this.snackbar_message = chip.chips_close_message
            this.snackbar = true
        },

        //log out if 401
        axiosError(e){
            console.log('GlobalVars axiosError')

            this.loading = false

            if(e.response && e.response.status == 401){
                this.$cookies.remove('login_remember')
                this.$store.commit('authToken', null) //reset in vuex
                delete this.axios.defaults.headers.common["Authorization"] //remove authorization
                this.axios.defaults.headers.common = {'Accept': `application/json, text/plain, */*`} //restore application json
            }

        },

        //basic api get to item
        apiGet(route){

            if(this.loading) return
            this.loading = true

            this.axios.get(route,
                {params: {}}
            ).then((response)=>{
                this.item = response.data
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.item = {}
                this.axiosError(e)
            });

        },

        //basic list get
        getSearchItems(name, api, keyword = null, vars = null){

            this.searchLoading[name] = true

            this.axios.get(api+'?' +
                'keyword='+ keyword +
                '&skip=0' +
                '&take=100' +
                '&order=id' +
                '&order_direction=ASC' + (vars ? vars : '')
            ).then((response)=>{
                this.searchItems[name] = response.data
                this.searchLoading[name] = false
            }).catch(e => {
                console.log(e.response.status)
                this.searchLoading[name] = false
                this.axiosError(e)
            });

        },
    }
}