<template>
  <v-app>
    <v-app-bar
        app
        dark
        clipped-left
        clipped-right
    >
      <div class="d-flex align-center">
          <h1>
          JALOVIINA
          </h1>
          <v-btn
                  text
                  :color="page == 'admin' ? 'green' : null"
              @click="page = 'admin'"
              >
              Hallinta
          </v-btn>
          <v-btn
                  text
                  :color="page == 'reporting' ? 'green' : null"
                  @click="page = 'reporting'"
          >
              Raportointi
          </v-btn>
          <v-btn
                  @click="logout()"
                  text
          >
              Kirjaudu ulos
          </v-btn>
      </div>

    </v-app-bar>

    <v-navigation-drawer
        app
        clipped
        mobile-breakpoint="650"
        v-if="$store.getters.authToken"
    >
      <v-list>
          <template v-if="page == 'admin'">
              <v-list-item-group v-for="(nav,key) in admin_menu" :key="key">
                  <hr v-if="nav.icon == 'hr'"/>
                  <v-list-item v-else :to="'/'+nav.route">
                      <v-icon>{{ nav.icon }}</v-icon>
                      <v-list-item-title>{{ nav.label }}</v-list-item-title>
                  </v-list-item>

              </v-list-item-group>
          </template>
          <template v-if="page == 'reporting'">
              <template v-for="(nav,key) in reporting_menu">
                  <v-list-item :key="'nav'+key">
                      <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                    text
                                    left
                                    v-bind="attrs"
                                    v-on="on"
                                    class="white--text"
                              >
                                  <v-icon v-if="nav.icon">{{ nav.icon }}</v-icon>
                                  <transition name="fade" mode="out-in">
                                      <v-list-item-title>{{ nav.label }}</v-list-item-title>
                                  </transition>
                              </v-btn>
                          </template>
                          <v-list>
                              <v-list-item v-for="(navmenu,key) in nav.menu" :key="key+'menu'" :to="'/'+navmenu.route">
                                  <v-icon v-if="navmenu.icon">{{ navmenu.icon }}</v-icon>
                                  {{ navmenu.label }}
                              </v-list-item>
                          </v-list>
                      </v-menu>
                  </v-list-item>
              </template>
          </template>

      </v-list>

    </v-navigation-drawer>

    <v-main>
      <v-container fluid>

        <template v-if="$store.getters.authToken">
      <!--<keep-alive v-if="$store.getters.authToken">-->
          <router-view />
        </template>

      <!--</keep-alive>-->
      <v-card v-else width="600" class="ma-auto">
        <v-card-text>
          <v-form>
          <div>
            <h2>Kirjaudu sisään</h2>
            <v-text-field
                type="text"
                v-model="login_email"
                label="email-osoite"
                attributes="username"
            />
          </div>
          <div>
            <v-text-field
                type="password"
                v-model="login_pass"
                label="salasana"
                attributes="current-password"
            />
          </div>
          <div>
              <v-btn
                      class="v-btn green"
                      @click="login"
                      :disabled="api_login_call"
              >
                  <template v-if="api_login_call">Kirjaudutaan...</template>
                  <template v-else>Kirjaudu sisään</template>
              </v-btn>
            <v-alert v-if="login_errors" class="ma-4 red">Kirjautuminen epäonnistui!</v-alert>
          </div>
          </v-form>
        </v-card-text>
      </v-card>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import GlobalVars from "./mixins/GlobalVars";

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'admin.heliad.fi',
      meta: [
        {name: 'description', content: "Heliad admin"},
        {property: 'og:site_name', content: 'admin.heliad.fi'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'no-index, no-follow'}
      ],
    }
  },
  data() {
    return {
      login_email: null,
      login_pass: null,
      login_remember: true,
      login_errors: false,
      page: 'admin',
      admin_menu: [
          {route: 'agents', label: 'Agentit', icon: 'mdi-alpha-a-circle-outline'},
          {route: 'chat_aliases', label: 'Chat aliakset', icon: 'mdi-alpha-c-circle-outline'},
          {route: 'audio_aliases', label: 'Audio aliakset', icon: 'mdi-alpha-a-circle-outline'},
          {route: 'direct_marketings', label: 'Suoramarkkinointi', icon: 'mdi-alpha-s-circle-outline'},
      ],
      reporting_menu: [
          {label: 'Reaaliaikainen', icon: 'mdi-alpha-r-circle-outline', menu: [
                  {route: 'realtime_calls_agents', label: 'Puhelut ja agentit', icon: 'mdi-alpha-p-circle-outline'},
                  {route: 'realtime_cumulative', label: 'Kumulatiivinen', icon: 'mdi-alpha-k-circle-outline'},
                  //{route: 'salary_reporting_division', label: 'Tuntipalkkajako', icon: 'mdi-alpha-t-circle-outline'},
          ]},
          {label: 'Palkat', icon: 'mdi-alpha-p-circle-outline', menu: [
              {route: 'salary_reporting', label: 'Netvisor', icon: 'mdi-alpha-p-circle-outline'},
              {route: 'salary_reporting_division', label: 'Tuntipalkkajako', icon: 'mdi-alpha-t-circle-outline'},
          ]},
          {label: 'Laskutus', icon: 'mdi-alpha-l-circle-outline', menu: [
                  {route: 'connections_reporting', label: 'Operaattori', icon: 'mdi-alpha-o-circle-outline'},
                  {route: 'charging_channel_report', label: 'SMS-laskutuskanavat', icon: 'mdi-alpha-l-circle-outline'},
                  {route: 'prepaid_reporting', label: 'Prepaid', icon: 'mdi-alpha-p-circle-outline'},
                  {route: 'credio_revenue', label: 'Credio', icon: 'mdi-alpha-c-circle-outline'},
                  {route: 'customer_vouchers', label: 'Hyvitykset', icon: 'mdi-alpha-h-circle-outline'},
                  {route: 'vismapay_report', label: 'Vismapay', icon: 'mdi-alpha-v-circle-outline'},
            ]
          },
          {label: 'Seuranta', icon: 'mdi-alpha-s-circle-outline', menu: [
                  {route: 'calls_report', label: 'Puheluraportti', icon: 'mdi-alpha-p-circle-outline'},
                  {route: 'audio_cumulative_report', label: 'Kumulatiivinen puheluraportti', icon: 'mdi-alpha-k-circle-outline'},
                  {route: 'customers_report', label: 'Puhelinasiakasraportti', icon: 'mdi-alpha-a-circle-outline'},
                  {route: 'sms_monitoring', label: 'SMS monitorointi', icon: 'mdi-alpha-o-circle-outline'},
                  {route: 'sms_customers_report', label: 'SMS asiakasraportti', icon: 'mdi-alpha-s-circle-outline'},
                  {route: 'sms_report', label: 'SMS avainsanaraportti', icon: 'mdi-alpha-s-circle-outline'},
                  {route: 'chat_alias_report', label: 'Chat-aliasraportti', icon: 'mdi-alpha-c-circle-outline'},
                  {route: 'chat_messages_report', label: 'Chat-viestien seuranta', icon: 'mdi-alpha-c-circle-outline'},
                  {route: 'netsales_report', label: 'Verkkomyyntiraportti', icon: 'mdi-alpha-v-circle-outline'},
                  {route: 'direct_marketing_report', label: 'Suoramarkkinointiraportti', icon: 'mdi-alpha-v-circle-outline'},
                  {route: 'missing_agent_minutes', label: 'Puuttuvat minutit', icon: 'mdi-alpha-p-circle-outline'},
                  {route: 'user_tracking', label: 'Käyttäjien seuranta', icon: 'mdi-alpha-u-circle-outline'},
                  {route: 'counsel_service_messages_report', label: 'Asiantuntijaviestien seuranta', icon: 'mdi-alpha-a-circle-outline'},

                  {route: 'chatsession_report', label: 'Aikaperusteisten keskustelujen raportti', icon: 'mdi-alpha-a-circle-outline'},
              ]
          },
          {label: 'Työnohjaus', icon: 'mdi-alpha-t-circle-outline', menu: [
                  {route: 'audio_agent_report', label: 'Agenttien puheluraportti', icon: 'mdi-alpha-a-circle-outline'},
                  {route: 'chat_agent_report', label: 'Agenttien chat-raportti', icon: 'mdi-alpha-c-circle-outline'},
                  {route: 'unanswered_report', label: 'Vastaamattomat viestit tms.', icon: 'mdi-alpha-v-circle-outline'},
                  {route: 'unanswered_notifications_report', label: 'Viestimuistutuksien raportti', icon: 'mdi-alpha-v-circle-outline'},
              ]
          },
      ],
      selected_drawer_state: false,
      selected_mini_drawer_state: true,
      search: null,
      search_dialog: false,
      api_login_call: false
    }
  },
  mixins: [GlobalVars], //import default set of values
  components: {},
  methods: {
    login(){

      this.api_login_call = true

      this.login_errors = false

      this.axios.get('login', {
        params: {
          username: this.login_email,
          password: this.login_pass,
          expiration: 480,
          device_name: 'api',
        }
      })
          .then(response => {

            this.api_login_call = false
            this.axios.defaults.headers.common = {'Authorization': 'Bearer '+response.data}
            this.$store.commit('authToken', 'Bearer '+response.data) //set in vue

            //store token to cookie if want
            if(this.login_remember) this.$cookies.set('login_remember', response.data)

            //window.location.reload()
          })
          .catch(() => {
            this.api_login_call = false
            this.login_errors = true
          })
    },

    logout() {

      this.axios.get('login/out')
          .then(() => {
            this.$cookies.remove('login_remember')
            this.$store.commit('authToken', null) //reset in vuex
            delete this.axios.defaults.headers.common["Authorization"] //remove authorization
            this.axios.defaults.headers.common = {'Accept': `application/json, text/plain, */*`} //restore application json

          })
          .catch(() => {
            this.$cookies.remove('login_remember')
            this.$store.commit('authToken', null) //reset in vuex
            delete this.axios.defaults.headers.common["Authorization"] //remove authorization
            this.axios.defaults.headers.common = {'Accept': `application/json, text/plain, */*`} //restore application json
          });

    },
  },
  mounted(){

    //remember login
    if(this.$cookies.get("login_remember")){

      this.$store.commit('authToken', 'Bearer '+this.$cookies.get("login_remember")) //set in vue
      this.axios.defaults.headers.common = {'Authorization': 'Bearer '+ this.$cookies.get("login_remember")}
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.v-label {
  font-size: 0.8em;
}
.sub-page {
  margin-bottom: 250px;
}
</style>
