import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Agents" */ '../views/Agents.vue')
  },
  {
    path: '/audio_aliases',
    name: 'AudioAliases',
    component: () => import(/* webpackChunkName: "AudioAliases" */ '../views/AudioAliases.vue')
  },
  {
    path: '/agents',
    name: 'Agents',
    component: () => import(/* webpackChunkName: "Agents" */ '../views/Agents.vue')
  },
  {
    path: '/agent/:id/main',
    name: 'Agent',
    props: (route) => ({
      id: route.params.id,
      page: 'main',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/chat_aliases',
    name: 'AgentChatAliases',
    props: (route) => ({
      id: route.params.id,
      page: 'chat_aliases',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/audio_aliases',
    name: 'AgentAudioAliases',
    props: (route) => ({
      id: route.params.id,
      page: 'audio_aliases',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/contacts',
    name: 'AgentContacts',
    props: (route) => ({
      id: route.params.id,
      page: 'contacts',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/reservations',
    name: 'AgentReservations',
    props: (route) => ({
      id: route.params.id,
      page: 'reservations',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/activities',
    name: 'AgentActivities',
    props: (route) => ({
      id: route.params.id,
      page: 'activities',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/:id/audio_report',
    name: 'AgentAudioReport',
    props: (route) => ({
      id: route.params.id,
      page: 'audio_report',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },
  {
    path: '/agent/uusi',
    name: 'AgentNew',
    props: () => ({
      id: null,
      page: 'new',
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/Agent.vue')
  },

  {
    path: '/chat_aliases',
    name: 'ChatAliases',
    // route level code-splitting
    // this generates a separate chunk (aliases.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ChatAliases" */ '../views/ChatAliases.vue')
  },

    /*
    chat alias routes:
     */
  {
    path: '/chat_alias/:id/main',
    name: 'ChatAlias',
    props: (route) => ({
      id: route.params.id,
      page: 'main',
    }),
    component: () => import(/* webpackChunkName: "ChatAlias" */ '../views/ChatAlias.vue')
  },
  {
    path: '/chat_alias/uusi',
    name: 'ChatAliasNew',
    props: () => ({
      id: null,
      page: 'new',
    }),
    component: () => import(/* webpackChunkName: "ChatAlias" */ '../views/ChatAlias.vue')
  },
  {
    path: '/chat_alias/uusi/:id',
    name: 'ChatAliasNewAgent',
    props: (route) => ({
      id: null,
      page: 'new',
      agent_id: route.params.id
    }),
    component: () => import(/* webpackChunkName: "ChatAlias" */ '../views/ChatAlias.vue')
  },
  {
    path: '/chat_alias/:id/media',
    name: 'ChatAliasMedia',
    props: (route) => ({
      id: route.params.id,
      page: 'media',
    }),
    component: () => import(/* webpackChunkName: "ChatAlias" */ '../views/ChatAlias.vue')
  },
  {
    path: '/chat_alias/:id/alias_descriptions',
    name: 'ChatAliasDescriptions',
    props: (route) => ({
      id: route.params.id,
      page: 'alias_descriptions',
    }),
    component: () => import(/* webpackChunkName: "ChatAlias" */ '../views/ChatAlias.vue')
  },

  /*
  audio alias routes:
  */
  {
    path: '/audio_alias/:id/main',
    name: 'AudioAlias',
    props: (route) => ({
      id: route.params.id,
      page: 'main',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/uusi',
    name: 'AudioAliasNew',
    props: () => ({
      id: null,
      page: 'new',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/uusi/:id',
    name: 'AudioAliasNewAgent',
    props: (route) => ({
      id: null,
      page: 'new',
      agent_id: route.params.id
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/:id/ads',
    name: 'AudioAliasAds',
    props: (route) => ({
      id: route.params.id,
      page: 'ads',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/:id/audio_presentations',
    name: 'AudioAliasAudioPresentations',
    props: (route) => ({
      id: route.params.id,
      page: 'audio_presentations',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/:id/media',
    name: 'AudioAliasMedia',
    props: (route) => ({
      id: route.params.id,
      page: 'media',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/audio_alias/:id/audio_report',
    name: 'AliasAudioReport',
    props: (route) => ({
      id: route.params.id,
      page: 'audio_report',
    }),
    component: () => import(/* webpackChunkName: "AudioAlias" */ '../views/AudioAlias.vue')
  },
  {
    path: '/salary_reporting',
    name: 'SalaryReporting',
    component: () => import(/* webpackChunkName: "SalaryReporting" */ '../views/SalaryReporting.vue')
  },
  {
    path: '/credio_revenue',
    name: 'CredioReporting',
    component: () => import(/* webpackChunkName: "CredioReporting" */ '../views/CredioReporting.vue')
  },
  {
    path: '/credio_prepaid',
    name: 'CredioPrepaidReporting',
    component: () => import(/* webpackChunkName: "CredioReporting" */ '../views/CredioReporting.vue')
  },
  {
    path: '/prepaid_reporting',
    name: 'PrepaidReporting',
    component: () => import(/* webpackChunkName: "PrepaidReporting" */ '../views/PrepaidReporting.vue')
  },
  {
    path: '/salary_reporting_division',
    name: 'SalaryReportingDivision',
    component: () => import(/* webpackChunkName: "SalaryReportingDivision" */ '../views/SalaryReportingDivision.vue')
  },

  {
    path: '/connections_reporting',
    name: 'ConnectionsReporting',
    component: () => import(/* webpackChunkName: "ConnectionsReporting" */ '../views/ConnectionsReporting.vue')
  },
  {
    path: '/sms_monitoring',
    name: 'SmsMonitoring',
    component: () => import(/* webpackChunkName: "SmsMonitoring" */ '../views/SmsMonitoring.vue')
  },
  {
    path: '/customer_vouchers',
    name: 'CustomerVouchers',
    component: () => import(/* webpackChunkName: "CustomerVouchers" */ '../views/CustomerVouchers.vue')
  },
  {
    path: '/charging_channel_report',
    name: 'ChargingChannelReport',
    component: () => import(/* webpackChunkName: "ChargingChannelReport" */ '../views/ChargingChannelReport.vue')
  },
  {
    path: '/audio_cumulative_report',
    name: 'AudioCumulativeReport',
    component: () => import(/* webpackChunkName: "ChargingChannelReport" */ '../views/AudioCumulativeReport.vue')
  },
  {
    path: '/audio_agent_report',
    name: 'AudioAgentReport',
    component: () => import(/* webpackChunkName: "AudioAgentReport" */ '../views/AudioAgentReport.vue')
  },
  {
    path: '/chat_agent_report',
    name: 'ChatAgentReport',
    component: () => import(/* webpackChunkName: "ChatAgentReport" */ '../views/ChatAgentReport.vue')
  },
  {
    path: '/netsales_report',
    name: 'NetsalesReport',
    component: () => import(/* webpackChunkName: "NetsalesReport" */ '../views/NetsalesReport.vue')
  },
  {
    path: '/calls_report',
    name: 'CallsReport',
    component: () => import(/* webpackChunkName: "CallsReport" */ '../views/CallsReport.vue')
  },
  {
    path: '/customers_report',
    name: 'CustomersReport',
    component: () => import(/* webpackChunkName: "CustomersReport" */ '../views/CustomersReport.vue')
  },
  {
    path: '/sms_customers_report',
    name: 'ChatCustomersReport',
    component: () => import(/* webpackChunkName: "ChatCustomersReport" */ '../views/ChatCustomersReport.vue')
  },
  {
    path: '/direct_marketing_report',
    name: 'DirectMarketingReport',
    component: () => import(/* webpackChunkName: "DirectMarketingReport" */ '../views/DirectMarketingReport.vue')
  },
  {
    path: '/unanswered_report',
    name: 'UnansweredReport',
    component: () => import(/* webpackChunkName: "UnansweredReport" */ '../views/UnansweredReport.vue')
  },
  {
    path: '/chat_alias_report',
    name: 'ChatAliasReport',
    component: () => import(/* webpackChunkName: "ChatAliasReport" */ '../views/ChatAliasReport.vue')
  },
  {
    path: '/chat_messages_report',
    name: 'ChatMessagesReport',
    component: () => import(/* webpackChunkName: "ChatMessagesReport" */ '../views/ChatMessagesReport.vue')
  },
  {
    path: '/realtime_calls_agents',
    name: 'RealtimeCallsAgents',
    component: () => import(/* webpackChunkName: "RealtimeCallsAgents" */ '../views/RealtimeCallsAgents.vue')
  },
  {
    path: '/realtime_cumulative',
    name: 'RealtimeCumulative',
    component: () => import(/* webpackChunkName: "RealtimeCumulative" */ '../views/RealtimeCumulative.vue')
  },
    //missing_agent_minutes
  {
    path: '/missing_agent_minutes',
    name: 'MissingAgentMinutes',
    component: () => import(/* webpackChunkName: "MissingAgentMinutes" */ '../views/MissingAgentMinutes.vue')
  },
  {
    path: '/sms_report',
    name: 'SmsDetailsReport',
    component: () => import(/* webpackChunkName: "SmsReport" */ '../views/SmsReport.vue')
  },
  {
    path: '/user_tracking',
    name: 'UserTracking',
    component: () => import(/* webpackChunkName: "UserTracking" */ '../views/UserTracking.vue')
  },

  {
    path: '/vismapay_report',
    name: 'VismapayReport',
    component: () => import(/* webpackChunkName: "VismapayReport" */ '../views/VismapayReport.vue')
  },
  {
    path: '/counsel_service_messages_report',
    name: 'CounselServiceMessagesReport',
    component: () => import(/* webpackChunkName: "CounselServiceMessagesReport" */ '../views/CounselServiceMessagesReport.vue')
  },
  {
    path: '/unanswered_notifications_report',
    name: 'UnansweredNotificationsReport',
    component: () => import(/* webpackChunkName: "UnansweredNotificationsReport" */ '../views/UnansweredNotificationsReport.vue')
  },
  {
    path: '/direct_marketings',
    name: 'DirectMarketing',
    component: () => import(/* webpackChunkName: "DirectMarketing" */ '../views/DirectMarketing.vue')
  },
  {
    path: '/direct_marketing/uusi',
    name: 'DirectMarketingEditor',
    component: () => import(/* webpackChunkName: "DirectMarketing" */ '../views/DirectMarketingEditor.vue')
  },
  {
    path: '/direct_marketing/:id/muokkaa',
    name: 'DirectMarketingEditorEdit',
    props: (route) => ({
      id: Number(route.params.id),
      mode: 'edit'
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/DirectMarketingEditor.vue')
  },
  {
    path: '/direct_marketing/:id/kopioi',
    name: 'DirectMarketingEditorCopy',
    props: (route) => ({
      id: Number(route.params.id),
      mode: 'copy'
    }),
    component: () => import(/* webpackChunkName: "Agent" */ '../views/DirectMarketingEditor.vue')
  },
  {
    path: '/chatsession_report',
    name: 'ChatSessionReport',
    component: () => import(/* webpackChunkName: "DirectMarketing" */ '../views/ChatSessionReport.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {

    if(savedPosition && savedPosition.y)
      return { x: 0, y: savedPosition.y }
    else
      return { x: 0, y: 0 }
  }
})



export default router
