import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import Echo from 'laravel-echo'
import cookie from './plugins/cookie'
import i18n from './locales/i18n'
import './assets/main.scss'
import './plugins/dayjs'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.config.productionTip = false
Vue.use(require('vue-moment'))

//remember to use Vue.js devtools 6.0 with vue 3

new Vue({
    router,
    store,
    vuetify,
    cookie,
    i18n,
    render: h => h(App)
}).$mount('#app')
